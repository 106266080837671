/* eslint-disable no-param-reassign */
import { ActionQueryEnum } from 'hooks/useActionQueryListener';
import { remainSearchParams } from 'config/constants';
import { ParsedUrlQuery } from 'querystring';
import { UrlRouter } from 'config/types/route';

export const getActionUrlSearch = (query: ActionQueryEnum): string => {
  if (typeof window === 'undefined') return '';

  const urlParams = new URL(window.location.href);
  urlParams.searchParams.set('action', query);
  return urlParams.search;
};

export const filterAllowedParams = (params: ParsedUrlQuery) => {
  if (Object.keys(params).length) {
    const filteredParams = Object.keys(params)
      .filter((key) => remainSearchParams.includes(key.toLowerCase()))
      .reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = params[key];
        return obj;
      }, {});

    return filteredParams;
  }

  return params;
};

export const generateUrlWithQueryString = (url: UrlRouter, params: ParsedUrlQuery) => {
  if (Object.keys(params).length) {
    const filteredParams = filterAllowedParams(params);

    if (Object.keys(filteredParams).length) {
      if (url[0] !== '/') url = `/${url}`;

      return `${url}?${serialize(filteredParams)}`;
    }

    return url;
  }

  return url;
};

const serialize = (obj: ParsedUrlQuery) => {
  return Object.keys(obj)
    .reduce((state, key) => {
      state.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key].toString())}`);
      return state;
    }, [])
    .join('&');
};
