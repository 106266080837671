import { TransitionOptions, UrlRouter } from 'config/types/route';
import { useRouter as useRouterNext } from 'next/router';
import { parse } from 'querystring';
import { useCallback, useMemo } from 'react';
import { filterAllowedParams, generateUrlWithQueryString } from 'utils/urlHelper';

export const useRouter = () => {
  const router = useRouterNext();

  const customPush = useCallback(
    (url: UrlRouter, as?: UrlRouter, options?: TransitionOptions) => {
      const params = router.query;

      if (typeof url === 'string') {
        const routeWithParams = generateUrlWithQueryString(url, params);
        router.push(routeWithParams, as, options);
      } else {
        const filteredParams = filterAllowedParams(params);
        const newQuery = url?.query;

        const newParms = typeof newQuery === 'string' ? parse(newQuery) : newQuery;

        const allParams = {
          ...newParms,
          ...filteredParams,
        };

        router.push(
          {
            ...url,
            query: allParams,
          },
          as,
          options,
        );
      }
    },
    [router],
  );

  return useMemo(
    () => ({
      ...router,
      push: customPush,
    }),
    [customPush, router],
  );
};
